import React from 'react';
import './Footer.css';

const Footer = () => (
  <footer>Matthias Southwick <a target="_blank" href="https://github.com/mythius/pickleball">Github</a></footer>
);

Footer.propTypes = {};

Footer.defaultProps = {};

export default Footer;
